/*
    MVP Verification of Mortgage form
*/
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  Button,
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
  ControlledStateSelect,
  NamedMemo,
  useZodHookForm,
  z,
} from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { DEFAULT_COUNTRY, StrictAddress } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

const FormShape = z
  .object({
    purpose: z.enum(['refi', 'newLoan', 'other']),
    purposeOther: z.string().optional(),
    name: z.string(),
    companyName: z.string(),
    address: StrictAddress,
    acceptTerms: z
      .boolean()
      .refine((val) => val, { message: 'The terms of authorization must be accepted before submitting the request' }),
  })
  .superRefine((data, ctx) => {
    const { purpose, purposeOther } = data;
    if (purpose === 'other' && isEmpty(purposeOther)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Please describe the purpose of verification`,
        path: ['purposeOther'],
      });
    }
  });

const PURPOSE_OPTIONS = [
  { value: 'refi', label: 'Refinancing' },
  { value: 'newLoan', label: 'New Loan Application' },
  { value: 'other', label: 'Other' },
];

export const RequestVOMForm = NamedMemo<Props>('RequestVOMForm', ({ loan }) => {
  const [showLoader, setShowLoader] = useState(false); // temp to make loader visible on submission (isSubmitting doesnt respect timeout)
  const {
    control,
    watch,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useZodHookForm(FormShape, {
    defaultValues: {
      purposeOther: undefined,
      name: '',
      companyName: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        locality: '',
        region: '',
        postcode: '',
        country: DEFAULT_COUNTRY,
      },
      acceptTerms: false,
    },
  });

  const selectedPurpose = watch('purpose');

  const onSubmit = handleSubmit(async () => {
    setShowLoader(true);
    setTimeout(() => {
      toast.success('Verification of Mortgage request has been received');
      setShowLoader(false);
      reset();
    }, 600); // timeout to see loader busy state
  });

  return (
    <>
      <ManageLoanHeader
        title="Request a Verification of Mortgage (VOM)"
        subtitle="A verification of mortgage (VOM) is an official statement that verifies your existing loan terms and provides a rating of the payment history, including if the loan was current or delinquent for each month listed. It reflects only the most recent 12 months of your loan, excluding the current month."
      />

      <Form onSubmit={onSubmit}>
        <ControlledSelect
          options={PURPOSE_OPTIONS}
          control={control}
          fieldName="purpose"
          label="Purpose of Verification"
          placeholder="Select purpose"
        />

        {selectedPurpose === 'other' && (
          <div className="mt-3">
            <ControlledInput control={control} fieldName="purposeOther" placeholder="Purpose of Verification" />
          </div>
        )}

        <div className="mt-3">
          <ControlledInput
            control={control}
            fieldName="name"
            label="VOM Recipient Name"
            placeholder="Name of recipient"
          />
        </div>

        <div className="mt-3">
          <ControlledInput
            control={control}
            fieldName="companyName"
            label="VOM Company Name"
            placeholder="Name of company"
          />
        </div>

        <div className="mt-3">
          <ControlledInput
            control={control}
            fieldName="address.line1"
            label="Recipient Address"
            placeholder="Recipient address"
          />
        </div>
        <div className="mt-3">
          <ControlledInput
            control={control}
            fieldName="address.line2"
            label="Recipient Address Line 2"
            placeholder="Suite 100"
          />
        </div>
        <div className="d-flex gap-2 mt-3">
          <div>
            <ControlledInput control={control} fieldName="address.locality" label="City" placeholder="City" />
          </div>
          <div>
            <ControlledStateSelect control={control} fieldName="address.region" label="State" />
          </div>
          <div>
            <ControlledInput control={control} fieldName="address.postcode" label="Zip Code" placeholder="Zip code" />
          </div>
        </div>

        <div
          className={classNames('mt-4 u-border-1 p-2 u-fs-2', {
            'u-border-color-bark1': !errors.acceptTerms,
            'u-border-color-red1': errors.acceptTerms,
          })}
        >
          <ControlledCheckbox
            control={control}
            fieldName="acceptTerms"
            label={`I authorize ${loan.company.name} to provide the following information regarding loan ${loan.losId} to the authorized party listed above.`}
          />
        </div>

        <div className="mt-4 w-100 d-flex justify-content-end">
          <Button
            type="submit"
            className="mt-3"
            variant="primary"
            size="md"
            onClick={onSubmit}
            loading={isSubmitting || showLoader}
          >
            Submit Request
          </Button>
        </div>
      </Form>
    </>
  );
});
