import { MonthNumbers } from 'luxon';
import * as z from 'zod';

import { CompanyReportId, LoanCreditReportId } from './BrandedIds';
import { zodBrandedUuid, zodDateOrString } from './utils';

export type CreditReportCycle = { month: MonthNumbers; year: number };

export const CreditReportField = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string(),
});
export type CreditReportField = z.infer<typeof CreditReportField>;

export const CreditReportSegment = z.object({
  name: z.string(),
  value: z.string(),
  fields: CreditReportField.array(),
});
export type CreditReportSegment = z.infer<typeof CreditReportSegment>;

export const CreditReport = z.object({
  content: z.string(),
  segments: CreditReportSegment.array(),
});
export type CreditReport = z.infer<typeof CreditReport>;

export const LoanCreditReport = z.object({
  id: zodBrandedUuid<LoanCreditReportId>(),
  companyReportId: zodBrandedUuid<CompanyReportId>().optional(),
  report: z.string(),
  createdAt: zodDateOrString,
});
export type LoanCreditReport = z.infer<typeof LoanCreditReport>;
