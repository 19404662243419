import { z } from 'zod';

import { AppraisalId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const Appraisal = z.object({
  id: zodBrandedUuid<AppraisalId>(),
  amount: z.number().nonnegative(),
  date: zodDateOrString,
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export type Appraisal = z.infer<typeof Appraisal>;
