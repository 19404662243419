import {
  DayAndTimeRange as GqlDayAndTimeRange,
  Timezone as GqlTimezone,
  Weekday as GqlWeekday,
} from '@willow/graphql-iso/src/app';
import { DayAndTimeRange as GqlPortalDayAndTimeRange } from '@willow/graphql-iso/src/portal';
import { DayAndTimeRange, Timezone, Weekday } from '@willow/types-iso';

import { combineStringList } from './combineStringList';

export function formatWeekday(weekday: Weekday, format: 'd' | 'ddd'): string;
export function formatWeekday(weekday: GqlWeekday, format?: 'd' | 'ddd'): string;
export function formatWeekday(weekday: Weekday | GqlWeekday, format?: 'd' | 'ddd'): string {
  switch (weekday) {
    case 'monday':
    case GqlWeekday.Monday:
      return format === 'd' ? 'M' : format === 'ddd' ? 'Mon' : 'Monday';
    case 'tuesday':
    case GqlWeekday.Tuesday:
      return format === 'd' ? 'Tu' : format === 'ddd' ? 'Tue' : 'Tuesday';
    case 'wednesday':
    case GqlWeekday.Wednesday:
      return format === 'd' ? 'W' : format === 'ddd' ? 'Wed' : 'Wednesday';
    case 'thursday':
    case GqlWeekday.Thursday:
      return format === 'd' ? 'Th' : format === 'ddd' ? 'Thu' : 'Thursday';
    case 'friday':
    case GqlWeekday.Friday:
      return format === 'd' ? 'F' : format === 'ddd' ? 'Fri' : 'Friday';
    case 'saturday':
    case GqlWeekday.Saturday:
      return format === 'd' ? 'Sa' : format === 'ddd' ? 'Sat' : 'Saturday';
    case 'sunday':
    case GqlWeekday.Sunday:
      return format === 'd' ? 'Su' : format === 'ddd' ? 'Sun' : 'Sunday';
    default:
      return '';
  }
}

export function formatTimezone(timezone: GqlTimezone): string;
export function formatTimezone(timezone: Timezone): string;
export function formatTimezone(timezone: Timezone | GqlTimezone): string {
  switch (timezone) {
    case 'America/Los_Angeles':
    case GqlTimezone.AmericaLosAngeles:
      return 'PT';
    case 'America/Denver':
    case GqlTimezone.AmericaDenver:
      return 'MT';
    case 'America/Chicago':
    case GqlTimezone.AmericaChicago:
      return 'CT';
    case 'America/New_York':
    case GqlTimezone.AmericaNewYork:
      return 'ET';
    default:
      throw new Error(`Unsupported timezone ${timezone}`);
  }
}

export const getHours = (dayHour: number) => dayHour % 12 || 12;

export const getAmOrPm = (dayHour: number) => (dayHour >= 12 ? 'P' : 'A');

const formatIntervalTime = (time: number): string => {
  const hour24 = Math.floor(time);
  const hours12 = getHours(hour24);
  const amOrPm = getAmOrPm(time);
  const minutes = Math.round((time - hour24) * 60);
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${hours12}:${formattedMinutes}${amOrPm}`;
};

// Followup: overload not working
export function formatIsoDayAndTimeRange(dayAndTimeRange: DayAndTimeRange): string {
  const { startDay, endDay, startTime, endTime, timezone } = dayAndTimeRange;
  const formattedStartDay = formatWeekday(startDay, 'd');
  const formattedEndDay = formatWeekday(endDay, 'd');
  const formattedDays =
    formattedStartDay === formattedEndDay ? formattedStartDay : `${formattedStartDay}–${formattedEndDay}`;

  return `${formattedDays}, ${formatIntervalTime(startTime)}–${formatIntervalTime(endTime)} ${formatTimezone(
    timezone,
  )}`;
}

export function formatGqlDayAndTimeRange(dayAndTimeRange: GqlDayAndTimeRange | GqlPortalDayAndTimeRange): string {
  const { startDay, endDay, startTime, endTime, timezone } = dayAndTimeRange;
  const formattedStartDay = formatWeekday(startDay, 'd');
  const formattedEndDay = formatWeekday(endDay, 'd');
  const formattedDays =
    formattedStartDay === formattedEndDay ? formattedStartDay : `${formattedStartDay}–${formattedEndDay}`;

  return `${formattedDays}, ${formatIntervalTime(startTime)}–${formatIntervalTime(endTime)} ${formatTimezone(
    timezone,
  )}`;
}

export function formatIsoDayAndTimeRangeList(dayAndTimeRanges: DayAndTimeRange[]): string {
  return combineStringList(dayAndTimeRanges.map(formatIsoDayAndTimeRange));
}

export function formatGqlDayAndTimeRangeList(
  dayAndTimeRanges: GqlDayAndTimeRange[] | GqlPortalDayAndTimeRange[],
): string {
  return combineStringList(dayAndTimeRanges.map(formatGqlDayAndTimeRange));
}
