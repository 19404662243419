import { z } from 'zod';

export const CompanyReportSettings = z.object({
  compiledAccrualsAndPayments: z.boolean(),
  loanTransferDocuments: z.boolean(),
  losBalanceAndTransactions: z.boolean(),
  shellpointHelocs: z.boolean().default(false),
  kensieMaePayment: z.boolean().default(false),
  kensieMaeEscrowDisbursement: z.boolean().default(false),
  kensieMaePrincipalPrepayment: z.boolean().default(false),
});

export type CompanyReportSettings = z.infer<typeof CompanyReportSettings>;

export const DEFAULT_COMPANY_REPORT_SETTINGS: Required<CompanyReportSettings> = {
  // lower reports
  compiledAccrualsAndPayments: false,
  loanTransferDocuments: false,
  losBalanceAndTransactions: false,
  shellpointHelocs: false,

  // interlinc reports
  kensieMaePayment: false,
  kensieMaeEscrowDisbursement: false,
  kensieMaePrincipalPrepayment: false,
};
