import { z } from 'zod';

import { zodDateOrString } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const FromAccount = z.enum(['suspense', 'holdback']);
export const ToAccount = z.enum(['escrow', 'holdback', 'suspense', 'principal']);

export const CreateBalanceReallocationEvent = BaseLoanEvent.extend({
  type: z.literal('createBalanceReallocation'),
  fromAccount: FromAccount,
  toAccount: ToAccount,
  total: z.number(),
  date: zodDateOrString,
  comment: z.string().optional(),
});
export type CreateBalanceReallocationEvent = z.infer<typeof CreateBalanceReallocationEvent>;
