import { every, get, omit } from 'lodash';
import { Except } from 'type-fest';
import * as z from 'zod';

import { Address, AddressInput } from '@willow/graphql-iso/src/app';
import { Address as ZodAddress, DEFAULT_COUNTRY, StrictAddress } from '@willow/types-iso';

export const getDefaultAddress = (): ZodAddress => ({
  line1: '',
  line2: '',
  line3: '',
  line4: '',
  locality: '',
  region: '',
  postcode: '',
  country: DEFAULT_COUNTRY,
});

export const EMPTY_ADDRESS = z.object({
  line1: z.union([z.undefined(), z.literal('')]),
  line2: z.union([z.undefined(), z.literal('')]),
  line3: z.union([z.undefined(), z.literal('')]),
  line4: z.union([z.undefined(), z.literal('')]),
  locality: z.union([z.undefined(), z.literal('')]),
  region: z.union([z.undefined(), z.literal('')]),
  postcode: z.union([z.undefined(), z.literal('')]),
  country: z.union([z.undefined(), z.literal(''), z.literal('US'), z.literal('USA')]),
});

export const OptionalValidAddress = z.union([EMPTY_ADDRESS, StrictAddress]);
export type TOptionalValidAddress = z.infer<typeof OptionalValidAddress>;

export const getAddressInput = (
  address?: Except<Address, '__typename', { requireExactProps: true }> | TOptionalValidAddress,
): AddressInput | undefined => {
  if (!address) return undefined;

  const isAddressEmpty = every(omit(address, ['__typename', 'country']), (v) => v === '' || v == null);
  if (isAddressEmpty) return undefined;

  return {
    ...address,
    line1: address.line1 ?? '',
    line2: address.line2 ?? '',
    line3: address.line3 ?? '',
    line4: address.line4 ?? '',
    locality: address.locality ?? '',
    region: address.region ?? '',
    postcode: address.postcode ?? '',
    country: address.country || DEFAULT_COUNTRY,
  };
};

const REQUIRED_ADDRESS_FIELDS: (keyof Address)[] = ['line1', 'locality', 'region', 'postcode'];
export const superRefineAddress =
  (pathToAddress: string[] = []): z.SuperRefinement<any> =>
  (data, ctx) => {
    // See if any required address fields are filled
    const hasAddress = REQUIRED_ADDRESS_FIELDS.map((key) => get(data, [...pathToAddress, key])).some((v) => !!v);
    if (!hasAddress) {
      return;
    }

    // See if all other required address fields are also filled. If not, add errors to the zod object.
    for (const key of REQUIRED_ADDRESS_FIELDS) {
      const path = [...pathToAddress, key];
      const value = get(data, path);
      if (value == null || value === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
          path,
        });
      }
    }
  };
