import { WorkoutPlanType } from '@willow/graphql-iso/src/app';
import { LoanReportingWorkoutPlanType } from '@willow/types-iso';

import { assertNever } from './assertNever';

export const formatWorkoutPlanType = (type: WorkoutPlanType) => {
  switch (type) {
    case WorkoutPlanType.PaymentDeferment:
      return 'Payment Deferment';
    case WorkoutPlanType.RepaymentPlan:
      return 'Repayment Plan';
    default:
      assertNever(type);
  }
};

export const formatWorkoutPlanTypeShort = (type: WorkoutPlanType) => {
  switch (type) {
    case WorkoutPlanType.PaymentDeferment:
      return 'Deferment';
    case WorkoutPlanType.RepaymentPlan:
      return 'Repayment';
    default:
      assertNever(type);
  }
};

export const formatLoanReportingWorkoutPlanType = (type: LoanReportingWorkoutPlanType) => {
  switch (type) {
    case LoanReportingWorkoutPlanType.Enum.PAYMENT_DEFERMENT:
      return 'Payment Deferment';
    case LoanReportingWorkoutPlanType.Enum.REPAYMENT_PLAN:
      return 'Repayment Plan';
    case LoanReportingWorkoutPlanType.Enum.NONE:
      return 'None';
    default:
      assertNever(type);
  }
};
