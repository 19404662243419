import { z } from 'zod';

import { BorrowerId, WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateDefermentWorkoutPlanSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export const GenerateDefermentWorkoutPlanSideEffect = GenerateDefermentWorkoutPlanSideEffectContext.extend({
  type: z.literal('generateDefermentWorkoutPlan'),
});
export type GenerateDefermentWorkoutPlanSideEffect = z.infer<typeof GenerateDefermentWorkoutPlanSideEffect>;
