import { DateTime } from 'luxon';
import { RRule, Weekday as RRuleWeekday, WeekdayStr as RRuleWeekdayStr } from 'rrule';

import { Weekday as GqlWeekday } from '@willow/graphql-iso/src/app';
import { RecurringReportFrequency, Weekday } from '@willow/types-iso';

import { convertGqlEnumToEnum } from '../gql/convertEnumToGqlEnum';

export { RRule };

const DEFAULT_START_DATE = DateTime.fromISO('2000-01-01').toUTC().toJSDate();
type DateOptions = { endDate?: DateTime; hour?: number; minute?: number };

export const gqlToIsoWeekday = convertGqlEnumToEnum<typeof GqlWeekday, Weekday>(GqlWeekday);

export const weekdayToRRuleWeekday = (weekday: Weekday): RRuleWeekdayStr => {
  switch (weekday) {
    case 'monday':
      return 'MO';
    case 'tuesday':
      return 'TU';
    case 'wednesday':
      return 'WE';
    case 'thursday':
      return 'TH';
    case 'friday':
      return 'FR';
    case 'saturday':
      return 'SA';
    case 'sunday':
      return 'SU';
    default:
      throw new Error(`Invalid weekday: ${weekday}`);
  }
};

const rruleToWeekday = (rrule: RRule): Weekday | undefined => {
  const { wkst } = rrule.options;

  switch (wkst) {
    case 0:
      return Weekday.enum.monday;
    case 1:
      return Weekday.enum.tuesday;
    case 2:
      return Weekday.enum.wednesday;
    case 3:
      return Weekday.enum.thursday;
    case 4:
      return Weekday.enum.friday;
    case 5:
      return Weekday.enum.saturday;
    case 6:
      return Weekday.enum.sunday;
    default:
      return undefined;
  }
};

const rruleToMonthDay = (rrule: RRule) => rrule.options.bymonthday[0];

const rruleToEndDate = (rrule: RRule): Date | null => rrule.options.until;

const rruleToFrequency = (rrule: RRule) => {
  const { freq } = rrule.options;
  switch (freq) {
    case RRule.DAILY:
      return RecurringReportFrequency.DAILY;
    case RRule.WEEKLY:
      return RecurringReportFrequency.WEEKLY;
    case RRule.MONTHLY:
      return RecurringReportFrequency.MONTHLY;
    case RRule.YEARLY:
    case RRule.HOURLY:
    case RRule.MINUTELY:
    case RRule.SECONDLY:
    default:
      throw new Error(`Unsupported frequency for rrule, freq = ${freq.toString()}`);
  }
};

export const rruleToRecurringReportFrequency = (rruleString: string) => {
  const hydratedRrule = RRule.fromString(rruleString);

  return {
    frequency: rruleToFrequency(hydratedRrule),
    weekday: rruleToWeekday(hydratedRrule),
    monthday: rruleToMonthDay(hydratedRrule),
    endDate: rruleToEndDate(hydratedRrule),
  };
};

const endOfDayUtc = (date?: DateTime) => (date ? date.toUTC().endOf('day').toJSDate() : undefined);

export const generateDailyRrule = ({ endDate, hour = 0, minute = 0 }: DateOptions) =>
  new RRule({
    // Unless we set a default here it will use the current time
    dtstart: DEFAULT_START_DATE,
    freq: RRule.DAILY,
    interval: 1,
    byhour: [hour],
    byminute: [minute],
    bysecond: [0],
    until: endOfDayUtc(endDate),
  });

export const generateWeeklyRrule = (weekday: RRuleWeekdayStr, { endDate, hour = 0, minute = 0 }: DateOptions) =>
  new RRule({
    dtstart: DEFAULT_START_DATE,
    freq: RRule.WEEKLY,
    interval: 1,
    byhour: [hour],
    byminute: [minute],
    bysecond: [0],
    wkst: RRuleWeekday.fromStr(weekday),
    byweekday: RRuleWeekday.fromStr(weekday),
    until: endOfDayUtc(endDate),
  });

export const generateMonthlyRrule = (monthday: number, { endDate, hour = 0, minute = 0 }: DateOptions) => {
  // If greater than 28, use -1 which means last day of the month
  const day = monthday > 28 ? -1 : monthday;

  return new RRule({
    dtstart: DEFAULT_START_DATE,
    freq: RRule.MONTHLY,
    interval: 1,
    byhour: [hour],
    byminute: [minute],
    bysecond: [0],
    bymonthday: [day],
    until: endOfDayUtc(endDate),
  });
};
