import { NamedMemo } from '@willow/shared-web';
import { PayoffAllowedStatuses } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { NavSection } from '../../components/layout/nav-section/NavSection';
import { NavSectionItem } from '../../components/layout/nav-section/NavSectionItem';
import { ManageLoanContact } from '../../components/manage-loan/Contact';
import { DrawRequest } from '../../components/manage-loan/DrawRequest';
import { EscrowWaiverRequest } from '../../components/manage-loan/EscrowWaiverRequest';
import { GeneralInquiry } from '../../components/manage-loan/GeneralInquiry';
import { ManageLoanMessaging } from '../../components/manage-loan/Messaging';
import { PaymentAssistanceInquiry } from '../../components/manage-loan/PaymentAssistanceInquiry';
import { PayoffRequestForm } from '../../components/manage-loan/PayoffRequestForm';
import { RequestPMICancellation } from '../../components/manage-loan/RequestPMICancellation';
import { RequestVOMForm } from '../../components/manage-loan/RequestVOMForm';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';

export interface Props {
  loan: PortalSelectedLoan;
}

export const ManageLoanPage = NamedMemo<Props>('ManageLoanPage', ({ loan }) => {
  const {
    company: {
      adminSettings: { customerServiceBotEnabled, whedaEnabled },
      portalSettings,
    },
    currentSnapshot: {
      loanStatus,
      drawsEnabled,
      currentPaymentConfig: { monthlyMortgageInsurancePayment },
    },
  } = loan;

  const { borrowerRequests } = portalSettings || {};
  const {
    payoffRequestEnabled,
    drawRequestEnabled,
    paymentAssistanceInquiryEnabled,
    escrowWaiverRequestEnabled,
    pmiCancellationRequestEnabled,
    generalInquiryEnabled,
  } = borrowerRequests || {};

  return (
    <div>
      <Metadata metadata={{ title: 'Manage Loan', description: 'Assistance for common mortgage requests' }} />

      <PageHeading title="Manage Loan" subtitle="Assistance for common mortgage requests" />

      <NavSection>
        {whedaEnabled && (
          <NavSectionItem key="vom" label="Request a Verification of Mortgage">
            <RequestVOMForm loan={loan} />
          </NavSectionItem>
        )}
        {payoffRequestEnabled && PayoffAllowedStatuses.safeParse(loanStatus).success && (
          <NavSectionItem key="payoff" label="Request a Payoff Statement">
            <PayoffRequestForm loan={loan} />
          </NavSectionItem>
        )}

        {drawRequestEnabled && drawsEnabled && (
          <NavSectionItem key="drawRequest" label="Request a Draw">
            <DrawRequest loan={loan} />
          </NavSectionItem>
        )}

        {paymentAssistanceInquiryEnabled && (
          <NavSectionItem key="paymentAssistance" label="Payment Assistance Inquiry">
            <PaymentAssistanceInquiry loan={loan} />
          </NavSectionItem>
        )}

        {escrowWaiverRequestEnabled && (
          <NavSectionItem key="escrowWaiver" label="Request an Escrow Waiver">
            <EscrowWaiverRequest loan={loan} />
          </NavSectionItem>
        )}

        {pmiCancellationRequestEnabled &&
          monthlyMortgageInsurancePayment != null &&
          monthlyMortgageInsurancePayment > 0 && (
            <NavSectionItem key="pmiCancel" label="Request PMI Cancellation">
              <RequestPMICancellation loan={loan} />
            </NavSectionItem>
          )}

        {generalInquiryEnabled && (
          <NavSectionItem key="generalInquiry" label="General Inquiry">
            <GeneralInquiry loan={loan} />
          </NavSectionItem>
        )}

        {customerServiceBotEnabled && (
          <NavSectionItem key="message" label="Send a Message">
            <ManageLoanMessaging loan={loan} />
          </NavSectionItem>
        )}

        <NavSectionItem key="contact" label="Contact Us">
          <ManageLoanContact loan={loan} />
        </NavSectionItem>
      </NavSection>
    </div>
  );
});
