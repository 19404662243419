import { z } from 'zod';

import { BorrowerId, WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateRepaymentWorkoutPlanSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export const GenerateRepaymentWorkoutPlanSideEffect = GenerateRepaymentWorkoutPlanSideEffectContext.extend({
  type: z.literal('generateRepaymentWorkoutPlan'),
});
export type GenerateRepaymentWorkoutPlanSideEffect = z.infer<typeof GenerateRepaymentWorkoutPlanSideEffect>;
