import * as z from 'zod';

import {
  BorrowerId,
  DocumentId,
  EscrowCompanyId,
  PaymentConfigId,
  PayoffId,
  QcDocumentId,
  SideEffectId,
  WorkoutPlanId,
} from '../BrandedIds';
import {
  BorrowerUploadDocument,
  DefermentWorkoutPlanDocument,
  DelinquentInterestAppliedDocument,
  EmailCopyDocument,
  EscrowAnalysisDocument,
  Form1098Document,
  GoodbyeLetterDocument,
  LenderUploadDocument,
  MortgageeLetterDocument,
  PaidInFullStatementDocument,
  PayoffStatementDocument,
  PreActiveWelcomeLetterDocument,
  RepaymentWorkoutPlanDocument,
} from '../documents/Templates';
import { EmailTemplateType } from '../emails';
import { Form1098Year } from '../qcDocument/documents/Form1098';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { AllBorrowerDocumentAccess, BorrowerDocumentAccess, WhiteListedBorrowerDocumentAccess } from './DocumentAccess';

export const LoanDocumentBase = z.object({
  id: zodBrandedUuid<DocumentId>(),
  url: z.string(),
  version: z.number(),
  comment: z.string().optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString.optional(),
  borrowerAccess: BorrowerDocumentAccess,
});
export type LoanDocumentBase = z.infer<typeof LoanDocumentBase>;

export const LoanDocumentDeliveryStatus = z.enum(['pending', 'sent', 'notSent']);
export type LoanDocumentDeliveryStatus = z.infer<typeof LoanDocumentDeliveryStatus>;

const LoanTransferDocumentBase = LoanDocumentBase.extend({
  deliveryStatus: LoanDocumentDeliveryStatus,
  deliveredAt: zodDateOrString.optional(),
  rescinded: z.boolean(),
});

/* Transfer Documents */
const LoanDocumentMortgagee = LoanTransferDocumentBase.extend({
  type: MortgageeLetterDocument,
  escrowCompanyId: zodBrandedUuid<EscrowCompanyId>(),
  borrowerAccess: WhiteListedBorrowerDocumentAccess, // no borrowers permitted to view (lender-only doc)
});
export type LoanDocumentMortgagee = z.infer<typeof LoanDocumentMortgagee>;

const LoanDocumentGoodbye = LoanTransferDocumentBase.extend({
  type: GoodbyeLetterDocument,
  borrowerAccess: WhiteListedBorrowerDocumentAccess, // borrowers not initially given access to the document
});
export type LoanDocumentGoodbye = z.infer<typeof LoanDocumentGoodbye>;

export const LoanTransferDocument = z.union([LoanDocumentMortgagee, LoanDocumentGoodbye]);
export type LoanTransferDocument = z.infer<typeof LoanTransferDocument>;

/* One-Off Documents */
const LoanDocumentPreActiveWelcomeLetter = LoanDocumentBase.extend({
  type: PreActiveWelcomeLetterDocument,
  borrowerAccess: AllBorrowerDocumentAccess,
});
export type LoanDocumentPreActiveWelcomeLetter = z.infer<typeof LoanDocumentPreActiveWelcomeLetter>;

const LoanDocumentPayoff = LoanDocumentBase.extend({
  type: PayoffStatementDocument,
  payoffId: zodBrandedUuid<PayoffId>(),
});
export type LoanDocumentPayoff = z.infer<typeof LoanDocumentPayoff>;

const LoanDocumentLenderUpload = LoanDocumentBase.extend({
  type: LenderUploadDocument,
  title: z.string(),
}).omit({ version: true });
export type LoanDocumentLenderUpload = z.infer<typeof LoanDocumentLenderUpload>;

const LoanDocumentBorrowerUpload = LoanDocumentBase.extend({
  type: BorrowerUploadDocument,
  title: z.string(),
  borrowerAccess: WhiteListedBorrowerDocumentAccess, // only borrower who uploads document has access
}).omit({ version: true });
export type LoanDocumentBorrowerUpload = z.infer<typeof LoanDocumentBorrowerUpload>;

const LoanDocumentPaidInFull = LoanDocumentBase.extend({
  type: PaidInFullStatementDocument,
  payoffId: zodBrandedUuid<PayoffId>(),
  borrowerAccess: AllBorrowerDocumentAccess,
});
export type LoanDocumentPaidInFull = z.infer<typeof LoanDocumentPaidInFull>;

export const LoanDocumentForm1098Copy = z.enum(['original', 'borrower', 'lender', 'irs']);
export type LoanDocumentForm1098Copy = z.infer<typeof LoanDocumentForm1098Copy>;

const LoanDocumentForm1098 = LoanDocumentBase.extend({
  type: Form1098Document,
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
  isCorrected: z.boolean(),
  year: Form1098Year,
  copy: LoanDocumentForm1098Copy,
  borrowerAccess: BorrowerDocumentAccess,
});
export type LoanDocumentForm1098 = z.infer<typeof LoanDocumentForm1098>;

const LoanDocumentEscrowAnalysis = LoanDocumentBase.extend({
  type: EscrowAnalysisDocument,
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
  effectiveDate: zodDateOrString,
  borrowerAccess: AllBorrowerDocumentAccess,
  isCorrected: z.boolean(),
});
export type LoanDocumentEscrowAnalysis = z.infer<typeof LoanDocumentEscrowAnalysis>;

const LoanDocumentDefermentWorkoutPlan = LoanDocumentBase.extend({
  type: DefermentWorkoutPlanDocument,
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
  borrowerAccess: BorrowerDocumentAccess,
});
export type LoanDocumentDefermentWorkoutPlan = z.infer<typeof LoanDocumentDefermentWorkoutPlan>;

const LoanDocumentRepaymentWorkoutPlan = LoanDocumentBase.extend({
  type: RepaymentWorkoutPlanDocument,
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
  borrowerAccess: BorrowerDocumentAccess,
});
export type LoanDocumentRepaymentWorkoutPlan = z.infer<typeof LoanDocumentRepaymentWorkoutPlan>;

const LoanDocumentDelinquentInterestApplied = LoanDocumentBase.extend({
  type: DelinquentInterestAppliedDocument,
  paymentConfigId: zodBrandedUuid<PaymentConfigId>(),
  borrowerAccess: BorrowerDocumentAccess,
});
export type LoanDocumentDelinquentInterestApplied = z.infer<typeof LoanDocumentDelinquentInterestApplied>;

const LoanDocumentEmailCopyBorrowerRecipient = z.object({
  type: z.literal('borrower'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
});
const LoanDocumentEmailCopyExternalRecipient = z.object({
  type: z.literal('external'),
  emailAddress: z.string(),
});
const LoanDocumentEmailCopyRecipient = z.union([
  LoanDocumentEmailCopyBorrowerRecipient,
  LoanDocumentEmailCopyExternalRecipient,
]);
const LoanDocumentEmailCopy = LoanDocumentBase.extend({
  type: EmailCopyDocument,
  emailTemplate: EmailTemplateType,
  emailTitle: z.string(),
  sideEffectId: zodBrandedUuid<SideEffectId>().optional(), // optional to avoid backfilling
  recipients: z.array(LoanDocumentEmailCopyRecipient).optional(), // optional to avoid backfilling
  borrowerAccess: WhiteListedBorrowerDocumentAccess, // lender-only view
});
export type LoanDocumentEmailCopy = z.infer<typeof LoanDocumentEmailCopy>;

/* -------------------- */
export const LoanDocument = z.discriminatedUnion('type', [
  LoanDocumentPreActiveWelcomeLetter,
  LoanDocumentMortgagee,
  LoanDocumentGoodbye,
  LoanDocumentPayoff,
  LoanDocumentLenderUpload,
  LoanDocumentBorrowerUpload,
  LoanDocumentPaidInFull,
  LoanDocumentForm1098,
  LoanDocumentEscrowAnalysis,
  LoanDocumentDefermentWorkoutPlan,
  LoanDocumentRepaymentWorkoutPlan,
  LoanDocumentDelinquentInterestApplied,
  LoanDocumentEmailCopy,
]);
export type LoanDocument = z.infer<typeof LoanDocument>;

/* HELPERS */
export function isLenderUploadDocument(item: LoanDocument): item is LoanDocumentLenderUpload {
  return item.type === 'lenderUpload';
}

export function isPayoffDocument(item: LoanDocument): item is LoanDocumentPayoff {
  return item.type === 'payoff';
}

export function isForm1098Document(item: LoanDocument): item is LoanDocumentForm1098 {
  return item.type === 'form1098';
}

export function isEscrowAnalysisDocument(item: LoanDocument): item is LoanDocumentEscrowAnalysis {
  return item.type === 'escrowAnalysis';
}

export function isTransferDocument(item: LoanDocument): item is LoanTransferDocument {
  return item.type === 'mortgagee' || item.type === 'goodbye';
}
export function isGoodbyeDocument(item: LoanDocument): item is LoanDocumentGoodbye {
  return item.type === 'goodbye';
}
export function isMortgageeDocument(item: LoanDocument): item is LoanDocumentMortgagee {
  return item.type === 'mortgagee';
}
