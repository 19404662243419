import { z } from 'zod';

import { PaymentId, TransferId } from '../BrandedIds';
import {
  GeneratePayoffReminderEmailType,
  LenderPaymentFailedEmail,
  PaymentLenderEmailTemplateType,
} from '../emails/Templates';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

const SendLenderBaseEmailSideEffectContext = BaseLoanIdSideEffect.extend({
  emailTemplate: GeneratePayoffReminderEmailType,
});
const SendLenderEmailSideEffect = SendLenderBaseEmailSideEffectContext.extend({
  type: z.literal('sendLenderEmail'),
});

const SendLenderPaymentEmailSideEffectContext = SendLenderBaseEmailSideEffectContext.extend({
  emailTemplate: PaymentLenderEmailTemplateType,
  paymentId: zodBrandedUuid<PaymentId>(),
  transferId: zodBrandedUuid<TransferId>().optional(),
});
const SendLenderPaymentEmailSideEffect = SendLenderPaymentEmailSideEffectContext.extend({
  type: z.literal('sendLenderEmail'),
});
export type SendLenderPaymentEmailSideEffect = z.infer<typeof SendLenderPaymentEmailSideEffect>;

const SendLenderPaymentFailedEmailSideEffectContext = SendLenderBaseEmailSideEffectContext.extend({
  emailTemplate: LenderPaymentFailedEmail,
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  failureReason: z.string().optional(),
});
const SendLenderPaymentFailedEmailSideEffect = SendLenderPaymentFailedEmailSideEffectContext.extend({
  type: z.literal('sendLenderEmail'),
});
export type SendLenderPaymentFailedEmailSideEffect = z.infer<typeof SendLenderPaymentFailedEmailSideEffect>;

export const SendLenderEmailSideEffectContext = z.union([
  SendLenderBaseEmailSideEffectContext,
  SendLenderPaymentFailedEmailSideEffectContext,
  SendLenderPaymentEmailSideEffectContext,
]);
export type SendLenderEmailSideEffect = z.infer<typeof SendLenderEmailSideEffect>;
