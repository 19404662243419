import { z } from 'zod';

import { Timezone, Weekday } from '@willow/graphql-iso/src/app';
import { HourIncrement } from '@willow/types-iso';

export const DayAndTimeRange = z.object({
  startTime: HourIncrement,
  startDay: z.nativeEnum(Weekday),
  endTime: HourIncrement,
  endDay: z.nativeEnum(Weekday),
  timezone: z.nativeEnum(Timezone),
});
export type TDayAndTimeRange = z.infer<typeof DayAndTimeRange>;

export const DefaultPreferredContactTime: TDayAndTimeRange = {
  startTime: 9,
  endTime: 17,
  timezone: Timezone.AmericaNewYork,
  startDay: Weekday.Monday,
  endDay: Weekday.Friday,
};
