import { range } from 'lodash';
import { ReactNode } from 'react';
import { Control, FieldPathByValue, FieldValues } from 'react-hook-form';

import { getAmOrPm, getHours } from '@willow/shared-iso';

import { assertNever } from '../../../utils';
import { ControlledSelect } from './ControlledSelect';

type Props<T extends FieldValues> = {
  timeIncrements: '15min' | 'hour';
  fieldName: FieldPathByValue<T, boolean | string | number | undefined | null>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  placeholder?: string;
  required?: boolean;
};

type TimeOption = { value: number; label: string };
const getWholeHourOptions = (): TimeOption[] => {
  return range(0, 24).map((dayHour) => {
    const hours = getHours(dayHour);
    const amOrPm = getAmOrPm(dayHour);

    return {
      value: dayHour,
      label: `${hours}${amOrPm}`,
    };
  });
};

const getQuarterHourOptions = (): TimeOption[] => {
  const quarterHours = [0, 15, 30, 45]; // 15-minute increments

  return range(0, 24).flatMap((dayHour) => {
    const hours = getHours(dayHour);
    const amOrPm = getAmOrPm(dayHour);

    return quarterHours.map((minutes) => {
      const formattedMinutes = minutes === 0 ? '00' : minutes;
      const value = dayHour + minutes / 60; // ie 14.75 for 2:45pm

      return {
        value,
        label: `${hours}:${formattedMinutes}${amOrPm}`,
      };
    });
  });
};

export function ControlledTimeofDaySelect<T extends FieldValues>(props: Props<T>) {
  let timeOptions;
  const { timeIncrements } = props;
  switch (timeIncrements) {
    case '15min':
      timeOptions = getQuarterHourOptions();
      break;
    case 'hour':
      timeOptions = getWholeHourOptions();
      break;
    default:
      assertNever(timeIncrements);
  }

  return <ControlledSelect options={timeOptions} {...props} isClearable={false} />;
}
