import { ReactNode } from 'react';
import { Control, FieldPathByValue, FieldValues } from 'react-hook-form';

import { Weekday } from '@willow/graphql-iso/src/app';

import { ControlledSelect } from './ControlledSelect';

type Props<T extends FieldValues> = {
  fieldName: FieldPathByValue<T, boolean | string | number | undefined | null>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  placeholder?: string;
  required?: boolean;
};

export function ControlledWeekdaySelect<T extends FieldValues>(props: Props<T>) {
  const options = [
    { value: Weekday.Monday, label: 'Monday' },
    { value: Weekday.Tuesday, label: 'Tuesday' },
    { value: Weekday.Wednesday, label: 'Wednesday' },
    { value: Weekday.Thursday, label: 'Thursday' },
    { value: Weekday.Friday, label: 'Friday' },
    { value: Weekday.Saturday, label: 'Saturday' },
    { value: Weekday.Sunday, label: 'Sunday' },
  ];

  return <ControlledSelect options={options} {...props} isClearable={false} />;
}
