import { ReactNode } from 'react';
import { Control, FieldPathByValue, FieldValues } from 'react-hook-form';

import { Timezone } from '@willow/graphql-iso/src/app';

import { ControlledSelect } from './ControlledSelect';

type Props<T extends FieldValues> = {
  fieldName: FieldPathByValue<T, boolean | string | number | undefined | null>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  placeholder?: string;
  required?: boolean;
};

export function ControlledTimezoneSelect<T extends FieldValues>(props: Props<T>) {
  const timezoneOptions = [
    { value: Timezone.AmericaLosAngeles, label: 'PT' },
    { value: Timezone.AmericaDenver, label: 'MT' },
    { value: Timezone.AmericaChicago, label: 'CT' },
    { value: Timezone.AmericaNewYork, label: 'ET' },
  ];

  return <ControlledSelect options={timezoneOptions} {...props} isClearable={false} />;
}
