import { values } from 'lodash';
import { createContext, ReactNode, useEffect, useState } from 'react';

import { PortalCompanyAdminSettings } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../App';

interface FeatureFlagContext {
  ff_autopaySettings: boolean;
  ff_portalBalanceForward: boolean;
  ff_applicationsEnabled: boolean;
  ff_applicationsEnabledSettings: {
    ladderLife: boolean;
  };
  ff_econsentEnabled: boolean;
  ff_phoneDialerFlagEnabled?: boolean;
}

const FEATURE_FLAG_CONTEXT_DEFAULT: FeatureFlagContext = {
  ff_autopaySettings: true,
  ff_portalBalanceForward: false,
  ff_applicationsEnabled: false,
  ff_applicationsEnabledSettings: {
    ladderLife: false,
  },
  ff_econsentEnabled: false,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureFlagContext = createContext<FeatureFlagContext>(FEATURE_FLAG_CONTEXT_DEFAULT);

export const FeatureFlagContextProvider = NamedMemo<{
  allCompanySettings: PortalCompanyAdminSettings[] | undefined;
  selectedLoan: PortalSelectedLoan | undefined;
  children: ReactNode;
}>('FeatureFlagContextProvider', ({ allCompanySettings, selectedLoan, children }) => {
  const [ff_autopaySettings, set_ff_autopaySettings] = useState(FEATURE_FLAG_CONTEXT_DEFAULT.ff_autopaySettings);
  const [ff_portalBalanceForward, set_ff_portalBalanceForward] = useState(
    FEATURE_FLAG_CONTEXT_DEFAULT.ff_portalBalanceForward,
  );
  const [ff_applicationsEnabled, set_ff_applicationsEnabled] = useState(
    FEATURE_FLAG_CONTEXT_DEFAULT.ff_applicationsEnabled,
  );
  const [ff_applicationsEnabledSettings, set_ff_applicationsEnabledSettings] = useState(
    FEATURE_FLAG_CONTEXT_DEFAULT.ff_applicationsEnabledSettings,
  );
  const [ff_econsentEnabled, set_ff_econsentEnabled] = useState(FEATURE_FLAG_CONTEXT_DEFAULT.ff_econsentEnabled);

  useEffect(() => {
    if (selectedLoan) {
      set_ff_autopaySettings(selectedLoan.company.adminSettings.autopayEnabled ?? true);
      set_ff_portalBalanceForward(selectedLoan.company.adminSettings.portalBalanceForward ?? true);

      const applicationsEnabled =
        selectedLoan.company.adminSettings.applicationsEnabled &&
        values(selectedLoan.company.adminSettings.applicationsEnabledSettings).some((v) => v === true);

      set_ff_applicationsEnabled(applicationsEnabled ?? false);
      set_ff_applicationsEnabledSettings(
        selectedLoan.company.adminSettings.applicationsEnabledSettings ??
          FEATURE_FLAG_CONTEXT_DEFAULT.ff_applicationsEnabledSettings,
      );

      set_ff_econsentEnabled(
        selectedLoan.company.adminSettings.econsentEnabled ?? FEATURE_FLAG_CONTEXT_DEFAULT.ff_econsentEnabled,
      );
    } else if (allCompanySettings) {
      set_ff_econsentEnabled(
        allCompanySettings.some((settings) => settings.econsentEnabled) ??
          FEATURE_FLAG_CONTEXT_DEFAULT.ff_econsentEnabled,
      );
    }
  }, [allCompanySettings, selectedLoan]);

  return (
    <FeatureFlagContext.Provider
      value={{
        ff_autopaySettings,
        ff_portalBalanceForward,
        ff_applicationsEnabled,
        ff_applicationsEnabledSettings,
        ff_econsentEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
});
