import { every, omit } from 'lodash';

import { Address as GqlAddress } from '@willow/graphql-iso/src/app';
import { Address } from '@willow/types-iso';

export function addressToString(address: Address | GqlAddress, includeCounty?: boolean): string {
  const isAddressEmpty = every(omit(address, ['__typename', 'country']), (v) => v === '' || v == null);
  if (isAddressEmpty) return '';

  const { line1, line2, line3, line4, locality, region, postcode } = address;
  return `${line1},${line2 ? ` ${line2},` : ''}${line3 ? ` ${line3},` : ''}${
    line4 ? ` ${line4},` : ''
  } ${locality}, ${region} ${postcode}${includeCounty && address.county ? ` (${address.county})` : ''}`;
}

export function addressToStringLines(address: Address | GqlAddress): {
  line1: string;
  line2: string;
} {
  const { line1, line2, line3, line4, locality, region, postcode } = address;
  return {
    line1: `${line1}${line2 ? `, ${line2}` : ''}${line3 ? `, ${line3}` : ''}${line4 ? `, ${line4}` : ''}`,
    line2: `${locality}, ${region} ${postcode}`,
  };
}
