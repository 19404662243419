import { InfoCircleOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Control, FieldPathByValue, FieldValues, get, useController } from 'react-hook-form';

import { Form, OverlayTrigger, Tooltip } from '../../../bootstrap';

type Props<T extends FieldValues> = {
  // Only allow string values to be used with this component
  fieldName: FieldPathByValue<T, boolean | undefined>;
  control: Control<T>;
  label: ReactNode;
  tooltip?: ReactNode;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  id?: string;
};

export function ControlledCheckbox<T extends FieldValues>({
  label,
  control,
  fieldName,
  hideErrorMessage,
  disabled,
  tooltip,
  id,
}: Props<T>) {
  const {
    field,
    formState: { errors },
  } = useController({ name: fieldName, control });
  return (
    <>
      <div className="d-flex align-items-center">
        <Form.Check
          id={id}
          className={classNames({
            'u-border-color-red1': get(errors, fieldName),
            'me-1': !!tooltip,
          })}
          type="checkbox"
          checked={field.value === true}
          {...field}
          // overrideable from props
          label={label}
          disabled={disabled}
        />
        {tooltip && (
          <OverlayTrigger trigger={['click', 'focus', 'hover']} rootClose overlay={<Tooltip>{tooltip}</Tooltip>}>
            <InfoCircleOutlined />
          </OverlayTrigger>
        )}
      </div>
      {!hideErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldName as any} // This *should* work without casting but it doesn't
          render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
        />
      )}
    </>
  );
}
