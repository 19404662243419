import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { STATE_ABBREVIATIONS } from '@willow/types-iso';

import { Hint } from '../Hint';

type Props<T extends FieldValues> = {
  fieldName: FieldPathByValue<T, string | undefined>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  placeholder?: string;
  required?: boolean;
};

export function ControlledStateSelect<T extends FieldValues>({
  control,
  fieldName,
  hideErrorMessage = false,
  label,
  hint,
  placeholder,
  required,
}: Props<T>) {
  const {
    field,
    formState: { errors },
  } = useController({ control, name: fieldName });
  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}
      <Form.Select {...field} className={classNames({ 'u-border-color-red1': !!get(errors, fieldName) })}>
        <option value="">{placeholder || 'Select State'}</option>
        {STATE_ABBREVIATIONS.map((stateAbbreviation) => (
          <option key={stateAbbreviation} value={stateAbbreviation}>
            {stateAbbreviation}
          </option>
        ))}
      </Form.Select>
      {hint && <Hint>{hint}</Hint>}
      {!hideErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldName as any}
          render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
        />
      )}
    </>
  );
}
