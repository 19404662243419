import * as z from 'zod';

export const AuditLogEventType = z.union([
  z.literal('loanCreation'),
  z.literal('flagAccount'),
  z.literal('setStatus'),
  z.literal('addAccountComment'), // deprecated
  z.literal('moveToTransfer'),
  z.literal('moveToPendingTransfer'),
  z.literal('modifyMaturityDate'),
  z.literal('updateMonthlyInterestRate'),
  z.literal('modifyPropertyAddress'),
  z.literal('addAppraisal'),

  z.literal('createFee'),
  z.literal('cancelFee'),
  z.literal('createAdvance'),

  z.literal('createPayment'),
  z.literal('reversePayment'),
  z.literal('createDisbursement'),
  z.literal('reverseDisbursement'),
  z.literal('createDraw'),
  z.literal('reverseDraw'),
  z.literal('createAutopayment'),
  z.literal('createDeposit'),
  z.literal('createBalanceReallocation'),

  z.literal('addAdditionalBorrower'),
  z.literal('removeAdditionalBorrower'),
  z.literal('changePrimaryBorrower'),
  z.literal('editBorrower'),

  z.literal('generateNextStatement'),
  z.literal('regenerateStatement'),
  z.literal('regenerateStatementPdf'),

  z.literal('enableAutopay'),
  z.literal('disableAutopay'),

  z.literal('editEscrowFields'),

  z.literal('saveDocumentPdf'),
  z.literal('updateDocumentTitle'),
  z.literal('updateDocumentBorrowerAccess'),
  z.literal('deleteDocument'),

  z.literal('createRequestedPayoff'),
  z.literal('createPayoff'),
  z.literal('expirePayoff'),
  z.literal('recordPayoff'),

  z.literal('updateEscrowDue'),
  z.literal('updateEscrowSetting'),
  z.literal('createEscrowSetting'),
  z.literal('updateEscrowPayment'),
  z.literal('updateLoan'),
  z.literal('updateLoanBoardedDates'),
  z.literal('updateIdsAndRemittanceData'),
  z.literal('updateBorrower'),
  z.literal('constructionToPermanent'),
  z.literal('createWorkoutPlan'),
  z.literal('cancelWorkoutPlan'),

  z.literal('updateInvestorRemittanceSplit'),

  z.literal('updateDelinquentInterestRate'),
  z.literal('waiveDelinquentInterest'),
  z.literal('deferDelinquentInterest'),

  z.literal('updateBillSettings'),
  z.literal('updateTransferData'),

  /**
   * Audit log only items
   */
  z.literal('updateLoanIds'),
  z.literal('removeBank'),
  z.literal('addBank'),
  z.literal('initiateMicrodeposits'),
  z.literal('sentMicrodepositEmail'),
  z.literal('firstLogin'),
  z.literal('isMaturing'),
  z.literal('nonPerformanceStatus'),
  z.literal('addUsioBank'),
  z.literal('deleteUsioBank'),
  z.literal('enableUsioBank'),
  z.literal('gracePastDue'),
  z.literal('updateBorrowerEconsent'),
  z.literal('editBorrowerDetails'),

  // loan notes
  z.literal('createLoanNote'),
  z.literal('pinLoanNote'),

  // workflows
  z.literal('createLoanWorkflow'),
  z.literal('closeLoanWorkflow'),

  // authorized contacts
  z.literal('addThirdPartyContact'),
  z.literal('editThirdPartyContact'),
  z.literal('deleteThirdPartyContact'),

  // entities
  z.literal('addLoanEntity'),
  z.literal('editLoanEntity'),
]);
export type AuditLogEventType = z.infer<typeof AuditLogEventType>;

export const AuditLogBorrowerEmailType = z.union([
  // side effects
  z.literal('sendWelcomeEmail'),
  z.literal('sendWelcomePreActiveEmail'),
  z.literal('sendWelcomeInterestReserveEmail'),
  z.literal('sendWelcomeAutoPayEmail'),
  z.literal('sendGoodbyeEmail'),
  z.literal('sendLatePaymentEmail'),
  z.literal('sendGracePastDueEmail'),
  z.literal('sendNextStatementEmail'),
  z.literal('sendNextStatementInterestReserveEmail'),
  z.literal('sendFinalPaymentEmail'),
  z.literal('sendRevisedStatementEmail'),
  z.literal('sendPaymentCancelledEmail'),
  z.literal('sendPaymentSubmittedEmail'),
  z.literal('sendPaymentPostedEmail'),
  z.literal('sendPaymentPostedInterestReserveEmail'),
  z.literal('sendPaymentFailedEmail'),
  z.literal('sendPaymentReminderEmail'),
  z.literal('sendPaymentPastDueReminderEmail'),
  z.literal('sendRescindGoodbyeEmail'),
  z.literal('sendCorrectionEmail'),
  z.literal('sendAutopayEnabledEmail'),
  z.literal('sendAutopayDisabledEmail'),
  z.literal('sendPayoffQuoteEmail'),
  z.literal('sendNewDocumentAvailable'),
  z.literal('sendPaidInFullStatementEmail'),
  z.literal('sendForm1098AvailableEmail'),
  z.literal('sendEscrowAnalysisAvailableEmail'),
  z.literal('sendDefermentCancelledEmail'),
  z.literal('sendBorrowerDelinquentInterestAppliedEmail'),
  z.literal('sendLoanPortalInstructionsEmail'),
]);
export const AuditLogSideEffectType = z.union([
  AuditLogBorrowerEmailType,

  // lender side effects
  z.literal('sendLenderPaymentSubmittedEmail'),
  z.literal('sendLenderPaymentCancelledEmail'),
  z.literal('sendLenderPaymentFailedEmail'),
  z.literal('sendLenderSidePaymentFailedEmail'),
  z.literal('sendLenderPaymentPostedEmail'),
  z.literal('sendLenderSweepCompleteEmail'),
  z.literal('sendLenderSweepFailedEmail'),
  z.literal('sendLenderGeneratePayoffReminderEmail'),

  // one off external emails
  z.literal('sendMortgageeLetterEmail'),
  z.literal('sendExternalPayoffQuoteEmail'),
  z.literal('sendExternalPaymentDueEmail'),

  z.literal('updateLoan'),
  z.literal('updateBorrower'),
]);
export type AuditLogSideEffectType = z.infer<typeof AuditLogSideEffectType>;

export const AuditLogType = z.union([AuditLogEventType, AuditLogSideEffectType]);
export type AuditLogType = z.infer<typeof AuditLogType>;

/* AUDIT LOG ONLY */
export const AuditLogOnlyWithCommentTypes: AuditLogType[] = ['nonPerformanceStatus', 'gracePastDue'];
