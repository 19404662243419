import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';

import { addPrecision } from './math';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_YYYY_MM_DD = 'yyyy-MM-dd';

// Slightly more flexible `formatDate` function that will accept unknown types
// and make sure we format using appropriate handler. We have lots of `any` values
// for dates in our codebase so this will help protect us against those.
export function formatDate(value: string | Date | DateTime, format?: string): string;
export function formatDate(value: unknown, format?: string): string | undefined;
export function formatDate(value: unknown, format: string = DATE_FORMAT) {
  const dateValue = getDateTime(value);

  if (!dateValue) return undefined;

  return dateValue.toFormat(format);
}

export function getDateTime(value: string | Date | DateTime): DateTime;
export function getDateTime(value: unknown): DateTime | undefined;
export function getDateTime(value: unknown) {
  if (isNil(value)) return undefined;
  if (typeof value === 'string' && isEmpty(value)) return undefined;

  if (DateTime.isDateTime(value)) {
    return value;
  }

  // Would call `.fromISO(...)` but that will fail on strings like `11/1/2023`
  if (typeof value === 'string') {
    return DateTime.fromJSDate(new Date(value));
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value);
  }

  if (typeof value === 'number') {
    return DateTime.fromSeconds(value);
  }

  throw new Error(`Unable to format date for value=${value}`);
}

export function getDate(value: string | Date | DateTime): Date;
export function getDate(value: unknown): Date | undefined;
export function getDate(value: unknown) {
  const parsedDate = getDateTime(value);

  if (!parsedDate) return;

  return parsedDate.toJSDate();
}

export function formatDateFromDate(date: Date, format: string = DATE_FORMAT): string {
  return formatDate(DateTime.fromJSDate(date), format);
}

export const dateFormatIgnoreTimezone = (date: Date, format: string = DATE_FORMAT): string =>
  DateTime.fromJSDate(date).setZone('local', { keepLocalTime: true }).toFormat(format);

export const dateFormatUTC = (date: Date | undefined, format: string = DATE_FORMAT): string =>
  date ? DateTime.fromJSDate(date, { zone: 'utc' }).toFormat(format) : '';

export function getNumMonthsBetweenDates(startDate: Date, endDate: Date): number {
  const luxonStartDate = DateTime.fromJSDate(startDate).startOf('day');
  const luxonEndDate = DateTime.fromJSDate(endDate).startOf('day');

  return luxonEndDate.diff(luxonStartDate, 'months').months;
}

export function isBeforeToday(date: Date, allowEqual = false): boolean {
  const luxonDateNow = DateTime.now().startOf('day');
  const luxonDate = DateTime.fromJSDate(date).startOf('day');

  const isBefore = luxonDateNow > luxonDate;

  return allowEqual ? isBefore || luxonDate.equals(luxonDateNow) : isBefore;
}

export function isAfterToday(date: Date, allowEqual = false): boolean {
  const luxonDateNow = DateTime.now();
  const luxonDate = DateTime.fromJSDate(date);

  const isAfter = luxonDate > luxonDateNow;

  return allowEqual ? isAfter || luxonDate.equals(luxonDateNow) : isAfter;
}

export function isSameDate(dateA: Date, dateB: Date): boolean {
  const luxonDateA = DateTime.fromJSDate(dateA).startOf('day');
  const luxonDateB = DateTime.fromJSDate(dateB).startOf('day');

  return luxonDateA.equals(luxonDateB);
}

/*
 Calculates loan term in months from start date to maturity date.
*/
export function calculateLoanTermMonths(startDate: Date, maturityDate: Date): number {
  // If start date is 1/1/2020, maturity date will be 12/1/2049, but we want return 360 months, hence adding 1
  return addPrecision(
    0,
    DateTime.fromJSDate(maturityDate).startOf('day').diff(DateTime.fromJSDate(startDate).startOf('day'), ['months'])
      .months,
    1,
  );
}
