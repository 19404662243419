import * as z from 'zod';

import { LoanId, LosId, PoolId } from '../BrandedIds';
import { PaymentStatus } from '../loan/Payments';
import { BalancePaymentSnapshot } from '../loan/Snapshots';

export interface LoanTransaction {
  id: string; // polymorphic, can be a PaymentId
  referenceId?: string;
  loanId: LoanId;
  poolId?: PoolId;
  borrowerLastName: string;
  timestamp: Date;
  processedDate?: Date;
  failureDate?: Date;
  type: TransactionType;
  status: PaymentStatus;
  method: TransactionMethod;
  disbursementType: DisbursementType;
  amount: number;
  expectedPayment: number;
  principal: number;
  additionalPrincipal: number;
  interest: number;
  escrow: number;
  fee: number;
  advance: number;
  advancePrincipal: number;
  advanceInterest: number;
  suspense: number;
  holdback: number;
  reserve: number;
  losId: LosId;
  entityName?: string;
  dueDate?: Date;
  createdAt: Date;
  updatedAt: Date;
  balanceSnapshot?: BalancePaymentSnapshot;
  changeId: number;
  investorSoldRate: number;
  investorBSoldRate: number;
  investorInterestAmount: number;
  investorBInterestAmount: number;
  servicerStripRate: number;
  servicerInterestAmount: number;
  investorFeeAmount: number;
  investorBFeeAmount: number;
  servicerFeeAmount: number;
  investorPrincipalOwnership: number;
  investorBPrincipalOwnership: number;
  investorPrincipalAmount: number;
  investorBPrincipalAmount: number;
  deferredPrincipal: number;
  deferredInterest: number;
  deferredFee: number;
  achBatch?: string;
}

export const TransactionType = z.enum([
  'payment',
  'paymentReversal',
  'disbursement',
  'disbursementReversal',
  'sweep',
  'draw',
  'drawReversal',
  'payoff',
  'boarding',
  'fee',
  'feeReversal',
  'paymentDeferment',
  'paymentDefermentReversal',
  'deposit',
  'depositReversal',
]);
export type TransactionType = z.infer<typeof TransactionType>;

export const DisbursementType = z
  .enum(['servicingTransfer', 'escrow', 'advance', 'payoff', 'forwardPayment', 'paymentAfterPayoff', 'other'])
  .optional();
export type DisbursementType = z.infer<typeof DisbursementType>;

export const TransactionMethod = z
  .enum([
    'CHECK',
    'WIRE',
    'PHONE',
    'ACH',
    'AUTHORIZED_ACH',
    'RESERVE',
    'BALANCE_FORWARD',
    'PRINCIPAL_PREPAYMENT',
    'BALANCE_REALLOCATION',
  ])
  .optional();
export type TransactionMethod = z.infer<typeof TransactionMethod>;
