import * as z from 'zod';

import {
  AuthorizedContactId,
  BorrowerId,
  EscrowPaymentHistoryItemId,
  LoanId,
  PaymentConfigId,
  PaymentId,
  TransferId,
} from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { LoanStatusActive, LoanStatusPendingTransfer, LoanStatusPreActive, LoanStatusTransferred } from './LoanStatus';

export const PaymentStatus = z.enum(['PENDING', 'PROCESSED', 'FAILED', 'CANCELLED']);
export type PaymentStatus = z.infer<typeof PaymentStatus>;

export const PaymentMethod = z.enum([
  'check',
  'wire',
  'phone',
  'dwolla',
  'reversal',
  'reserve',
  'reserveReversal',
  'balanceForward',
  'principalPrepayment',
  'authorizedACH',
  'balanceReallocation',
]);
export type PaymentMethod = z.infer<typeof PaymentMethod>;

export const NonAchPaymentMethods = z.enum([
  PaymentMethod.Enum.check,
  PaymentMethod.Enum.wire,
  PaymentMethod.Enum.phone,
  PaymentMethod.Enum.reserve,
]);
export type NonAchPaymentMethods = z.infer<typeof NonAchPaymentMethods>;

const DwollaBorrowerTransferType = z.enum(['balance']);

export const DwollaLenderTransferType = z.enum(['principal', 'escrow', 'suspense', 'suspenseRemaining', 'single']);
export type DwollaLenderTransferType = z.infer<typeof DwollaLenderTransferType>;

export const DwollaTransferType = z.union([DwollaBorrowerTransferType, DwollaLenderTransferType]);
export type DwollaTransferType = z.infer<typeof DwollaTransferType>;

export const DwollaSweepType = z.enum(['sweepToBalance', 'sweepToAccount']);
export type DwollaSweepType = z.infer<typeof DwollaSweepType>;

export type MassTransfer = Partial<Record<DwollaLenderTransferType, number>>;

export const DwollaBaseTransfer = z.object({
  id: zodBrandedUuid<TransferId>(),
  amount: z.number(),
  transferUrl: z.string(),
  status: PaymentStatus,
  paymentDate: zodDateOrString,
  type: DwollaTransferType,
});
export type DwollaBaseTransfer = z.infer<typeof DwollaBaseTransfer>;

// Dwolla transfer
export const Transfer = z.object({
  status: PaymentStatus,
  processedDate: zodDateOrString.optional(),
  toBalance: z.array(DwollaBaseTransfer),
  toAccount: z.array(DwollaBaseTransfer),
  failureCode: z.string().optional(),
});
export type Transfer = z.infer<typeof Transfer>;

export const UsioActiveTransfer = z.object({
  status: z.enum(['PENDING', 'PROCESSED', 'CANCELLED']),
  accountToken: z.string().optional(),
  batchedDate: zodDateOrString.optional(),
  batch: z.string().optional(),
  processedDate: zodDateOrString.optional(),
});
export const UsioFailedTransfer = z.object({
  status: z.literal('FAILED'),
  accountToken: z.string().optional(),
  batchedDate: zodDateOrString.optional(),
  batch: z.string().optional(),
  failureCode: z.string(),
});
export type UsioFailedTransfer = z.infer<typeof UsioFailedTransfer>;
export type UsioActiveTransfer = z.infer<typeof UsioActiveTransfer>;
export const UsioTransfer = z.union([UsioActiveTransfer, UsioFailedTransfer]);
export type UsioTransfer = z.infer<typeof UsioTransfer>;

const BorrowerPaymentPayer = z.object({
  id: zodBrandedUuid<BorrowerId>(),
  type: z.literal('borrower'),
});
export type BorrowerPaymentPayer = z.infer<typeof BorrowerPaymentPayer>;
const AuthorizedContactPaymentPayer = z.object({
  id: zodBrandedUuid<AuthorizedContactId>(),
  type: z.literal('authorizedContact'),
});
export type AuthorizedContactPaymentPayer = z.infer<typeof AuthorizedContactPaymentPayer>;
const ThirdPartyPaymentPayer = z.object({
  name: z.string(),
  type: z.literal('thirdParty'),
});
export type ThirdPartyPaymentPayer = z.infer<typeof ThirdPartyPaymentPayer>;
export const PaymentPayer = z.discriminatedUnion('type', [
  BorrowerPaymentPayer,
  AuthorizedContactPaymentPayer,
  ThirdPartyPaymentPayer,
]);
export type PaymentPayer = z.infer<typeof PaymentPayer>;

const PaymentBase = z.object({
  id: zodBrandedUuid<PaymentId>(),
  loanId: zodBrandedUuid<LoanId>(),
  payer: PaymentPayer,
  total: z.number(),
  transactionId: z.string(),
  paymentDate: zodDateOrString,
  failureDate: zodDateOrString.optional(),
  status: PaymentStatus,
  holdback: z.number().optional(),
  additionalPrincipal: z.number().optional(),
  additionalEscrow: z.number().optional(),
  additionalSuspense: z.number().optional(),
  comment: z.string().optional(),
  escrowShortageAnalysisId: zodBrandedUuid<EscrowPaymentHistoryItemId>().optional(),
  paymentConfigId: zodBrandedUuid<PaymentConfigId>().optional(),
});

export const NonAchPayment = PaymentBase.extend({
  method: z.enum([
    PaymentMethod.Enum.check,
    PaymentMethod.Enum.wire,
    PaymentMethod.Enum.phone,
    PaymentMethod.Enum.reversal,
    PaymentMethod.Enum.reserveReversal,
    PaymentMethod.Enum.balanceForward,
    PaymentMethod.Enum.principalPrepayment,
    PaymentMethod.Enum.balanceReallocation,
  ]),
});

export const ReservePayment = PaymentBase.extend({
  method: z.literal(PaymentMethod.Enum.reserve),
  transfer: Transfer.optional(),
  sweep: Transfer.optional(),
  isAutopayment: z.boolean().optional(),
});
export type ReservePayment = z.infer<typeof ReservePayment>;

export const DwollaPayment = PaymentBase.extend({
  method: z.literal(PaymentMethod.Enum.dwolla),
  transfer: Transfer.optional(),
  sweep: Transfer.optional(),
  isAutopayment: z.boolean().optional(),
});
export type DwollaPayment = z.infer<typeof DwollaPayment>;

const SweepEnabledPayment = z.union([DwollaPayment, ReservePayment]);
export type SweepEnabledPayment = z.infer<typeof SweepEnabledPayment>;

export const UsioPayment = PaymentBase.extend({
  method: z.literal(PaymentMethod.Enum.authorizedACH),
  isAutopayment: z.boolean().optional(),
  transfer: UsioTransfer.optional(), // TODO: make required
});
export type UsioPayment = z.infer<typeof UsioPayment>;

export const Payment = z.union([NonAchPayment, SweepEnabledPayment, UsioPayment]);
export type Payment = z.infer<typeof Payment>;

// DwollaBaseTransfer with added prisma fields
const PaymentTransfer = DwollaBaseTransfer.extend({
  paymentId: zodBrandedUuid<PaymentId>(),
  isSweep: z.boolean(),
});
export type PaymentTransfer = z.infer<typeof PaymentTransfer>;

export const PaymentAllowedStatuses = z.union([
  LoanStatusPreActive,
  LoanStatusActive,
  LoanStatusPendingTransfer,
  LoanStatusTransferred,
]);

export const isAchPayment = (payment: Payment): payment is DwollaPayment | UsioPayment =>
  ['dwolla', 'authorizedACH'].includes(payment.method);

export const isDwollaPayment = (payment: Payment): payment is DwollaPayment => payment.method === 'dwolla';
export const isDepositPayment = (payment: Payment): boolean => payment.payer.type === 'thirdParty';
