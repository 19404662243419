/*
    PaymentsDue: includes ALL of the payment statments
    PaymentToPaymentDue: includes all payments ever made (multiple payments can be made on a single statement)
*/
import { last, reverse, sumBy } from 'lodash';

import { AppPaymentDueFragment } from '@willow/graphql-iso/src/app';
import { PortalPaymentDueFragment, PortalPaymentFragment } from '@willow/graphql-iso/src/portal';

import { paymentDateFormat } from './paymentFormat';

export function orderPaymentsDue(paymentsDue: PortalPaymentDueFragment[]): PortalPaymentDueFragment[];
export function orderPaymentsDue(paymentsDue: AppPaymentDueFragment[]): AppPaymentDueFragment[];
export function orderPaymentsDue(
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment[] | AppPaymentDueFragment[] {
  // The most recent payment due is last in the array
  return reverse([...paymentsDue]);
}

export const getNextPaymentDue = (
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment | AppPaymentDueFragment | undefined => {
  // Get the next statement date by taking the last paymentDue item
  return last(paymentsDue.filter((pd) => !pd.status.workoutPlanType));
};

export function getUnfulfilledPaymentsDue(paymentsDue: PortalPaymentDueFragment[]): PortalPaymentDueFragment[];
export function getUnfulfilledPaymentsDue(paymentsDue: AppPaymentDueFragment[]): AppPaymentDueFragment[];
export function getUnfulfilledPaymentsDue(
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment[] | AppPaymentDueFragment[] {
  // The paymentsDue array contains a history of all statements
  // To get the current statements, filter amountRemaining > 0 to see if anything is owed to the lender
  const unfulfilledPaymentsDue = paymentsDue.filter((paymentDue) => paymentDue.status.amountRemainingFromBorrower > 0);
  return orderPaymentsDue(unfulfilledPaymentsDue) || [];
}

export function getOldestUnfulfilledPaymentDue(paymentsDue: PortalPaymentDueFragment[]): PortalPaymentDueFragment;
export function getOldestUnfulfilledPaymentDue(paymentsDue: AppPaymentDueFragment[]): AppPaymentDueFragment;
export function getOldestUnfulfilledPaymentDue(
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment | AppPaymentDueFragment | undefined {
  const unfulfilledPaymentsDue = paymentsDue.filter((paymentDue) => paymentDue.status.amountRemainingFromBorrower > 0);
  return unfulfilledPaymentsDue[0];
}

export const getPaymentsProcessingTotal = (paymentsProcessing: PortalPaymentFragment[]): number => {
  return sumBy(paymentsProcessing, (payment) => payment.total);
};

export const getNextPaymentDueDate = (
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): string | undefined => {
  const nextPaymentDue = getNextPaymentDue(paymentsDue);
  return paymentDateFormat(nextPaymentDue?.paymentDate);
};

export const getCollectablePaymentsDue = (
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment[] | AppPaymentDueFragment[] => {
  // We generate paymentsDue for some months that we don't expect to actually collect on
  // For those paymentsDue, the amountRemaining is set to 0
  return paymentsDue.filter(
    (paymentDue) =>
      paymentDue.status.workoutPlanType ||
      paymentDue.status.fulfilled ||
      (!paymentDue.status.fulfilled &&
        (paymentDue.status.amountRemainingFromBorrower > 0 || paymentDue.status.amountRemainingFromCredit > 0)),
  );
};
