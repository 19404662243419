import { DateTime } from 'luxon';
import { Opaque } from 'type-fest';

export type GqlIsoDate = Opaque<string, 'GqlIsoDate'>;

export function formatGqlIsoDate(value: Date): GqlIsoDate;
export function formatGqlIsoDate(value?: Date | undefined): GqlIsoDate | undefined;
export function formatGqlIsoDate(value?: Date): GqlIsoDate | undefined {
  if (typeof value === 'undefined') return;

  return DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') as GqlIsoDate;
}

export function parseGqlIsoDate(value: GqlIsoDate): Date;
export function parseGqlIsoDate(value: string): Date;
export function parseGqlIsoDate(value?: GqlIsoDate | null): Date | undefined;
export function parseGqlIsoDate(value?: string | GqlIsoDate | null): Date | undefined {
  if (!value) return;

  const parsedDate = DateTime.fromFormat(value, 'yyyy-MM-dd');

  if (!parsedDate.isValid) {
    throw new Error('Invalid Date');
  }

  return parsedDate.toJSDate();
}
