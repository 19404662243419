import * as z from 'zod';

import { RescindGoodbyeEmail } from '../emails/Templates';

/* Document Template Types */
export const PreActiveWelcomeLetterDocument = z.literal('preActiveWelcome');
export const MonthlyStatementDocument = z.literal('monthlyStatement');
export const LenderUploadDocument = z.literal('lenderUpload');
export const BorrowerUploadDocument = z.literal('borrowerUpload');
export const GoodbyeLetterDocument = z.literal('goodbye');
export const MortgageeLetterDocument = z.literal('mortgagee');
export const PayoffStatementDocument = z.literal('payoff');
export const PaidInFullStatementDocument = z.literal('paidInFull');
export const Form1098Document = z.literal('form1098');
export const EscrowAnalysisDocument = z.literal('escrowAnalysis');
export const DefermentWorkoutPlanDocument = z.literal('defermentWorkoutPlan');
export const RepaymentWorkoutPlanDocument = z.literal('repaymentWorkoutPlan');
export const DelinquentInterestAppliedDocument = z.literal('delinquentInterestApplied');
export const EmailCopyDocument = z.literal('emailCopy');

export const LoanDocumentType = z.union([
  PreActiveWelcomeLetterDocument,
  MonthlyStatementDocument,
  GoodbyeLetterDocument,
  LenderUploadDocument,
  BorrowerUploadDocument,
  MortgageeLetterDocument,
  PayoffStatementDocument,
  PaidInFullStatementDocument,
  Form1098Document,
  EscrowAnalysisDocument,
  DefermentWorkoutPlanDocument,
  RepaymentWorkoutPlanDocument,
  DelinquentInterestAppliedDocument,
  EmailCopyDocument,
]);
export type LoanDocumentType = z.infer<typeof LoanDocumentType>;

/* Documents that have an associated mail task */
export const TaskEmailDocumentType = z.union([
  // email copy types
  RescindGoodbyeEmail,

  // documents
  PreActiveWelcomeLetterDocument,
  MonthlyStatementDocument,
  GoodbyeLetterDocument,
  MortgageeLetterDocument,
  Form1098Document,
  EscrowAnalysisDocument,
]);
export type TaskEmailDocumentType = z.infer<typeof TaskEmailDocumentType>;
