import { z } from 'zod';

import { zodDateOrString } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const AddAppraisalEvent = BaseLoanEvent.extend({
  type: z.literal('addAppraisal'),
  amount: z.number().nonnegative(),
  date: zodDateOrString,
});

export type AddAppraisalEvent = z.infer<typeof AddAppraisalEvent>;
