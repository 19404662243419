import { z } from 'zod';

import { BorrowerId, LoanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const DisableUsioAccountSideEffectContext = BaseLoanIdSideEffect.extend({
  loanId: zodBrandedUuid<LoanId>(),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  accountId: z.string(),
  failureCode: z.string(),
});

export const DisableUsioAccountSideEffect = DisableUsioAccountSideEffectContext.extend({
  type: z.literal('disableUsioAccount'),
});
export type DisableUsioAccountSideEffect = z.infer<typeof DisableUsioAccountSideEffect>;
