import * as z from 'zod';

export const Weekday = z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']);
export type Weekday = z.infer<typeof Weekday>;

export const DayOfMonth = z.number().int().gte(0).lte(31);
export type DayOfMonth = z.infer<typeof DayOfMonth>;

export const Timezone = z.enum(['America/Los_Angeles', 'America/Denver', 'America/Chicago', 'America/New_York']);
export type Timezone = z.infer<typeof Timezone>;

export const HourIncrement = z.number().gte(0).lte(23.99); // 24-hour base. allow increments (ie 2:30 = 14.5)
export type HourIncrement = z.infer<typeof HourIncrement>;

export const HourWhole = z.number().int().gte(0).lte(23); // 24-hour base. on the hour only
export type HourWhole = z.infer<typeof HourWhole>;

export const DayAndTimeRange = z.object({
  startTime: HourIncrement,
  startDay: Weekday,
  endTime: HourIncrement,
  endDay: Weekday,
  timezone: Timezone,
});
export type DayAndTimeRange = z.infer<typeof DayAndTimeRange>;
