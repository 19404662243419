import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { LenderPaymentFailedEmail } from '../emails/Templates';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const SendLenderEmailEvent = BaseLoanEvent.extend({
  type: z.literal('sendLenderEmail'),
  template: LenderPaymentFailedEmail, // TODO: add more over time
  paymentId: zodBrandedUuid<PaymentId>().optional(),
  failureReason: z.string().optional(),
});
export type SendLenderEmailEvent = z.infer<typeof SendLenderEmailEvent>;
