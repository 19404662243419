import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

export const GenerateNextStatementEvent = BaseLoanEvent.extend({
  type: z.literal('generateNextStatement'),
  ignoreDateCheck: z.boolean().optional(),
  amortizationMonth: z.number().optional(),
});
export type GenerateNextStatementEvent = z.infer<typeof GenerateNextStatementEvent>;
