import * as z from 'zod';

export const PortalPaymentStopSetting = z.enum(['none', 'exact', 'any']);
export type PortalPaymentStopSetting = z.infer<typeof PortalPaymentStopSetting>;

export const StopSettings = z.object({
  portalPayments: PortalPaymentStopSetting,
  emailsPaused: z.boolean(),
  documentAccessPaused: z.boolean(),
  drawsPaused: z.boolean(),
  phoneDialerPaused: z.boolean(),
});
export type StopSettings = z.infer<typeof StopSettings>;

export const DEFAULT_STOP_SETTINGS: StopSettings = {
  portalPayments: 'any',
  emailsPaused: false,
  documentAccessPaused: false,
  drawsPaused: false,
  phoneDialerPaused: false,
};
