import * as z from 'zod';

import { Address, PaymentAddress } from '../Address';
import { CompanyId } from '../BrandedIds';
import { EmailSettings } from '../EmailSettings';
import { CompanyLogos } from '../Logos';
import { PortalSettings } from '../PortalSettings';
import { SupportHours } from '../SupportHours';
import { CompanyBillingSettings, DEFAULT_BILLING_SETTINGS } from './BillingSettings';
import { CompanyReportSettings } from './CompanyReportSettings';
import { DocumentSettings } from './DocumentSettings';
import { CompanyFeeSettings } from './FeeSettings';
import { CompanyLoanControlSettings } from './LoanControlSettings';
import { CompanyNonPerformanceSettings } from './NonPerformanceSettings';

export interface Company {
  id: CompanyId;
  name: string;
  modalDisplayName: string;
  supportEmail: string;
  address?: Address;
  paymentAddress?: PaymentAddress;
  supportPhone: string;
  supportHours?: SupportHours;
  website?: string;
  emailSettings: EmailSettings;
  paymentsPaused: boolean;
  adminSettings: CompanyAdminSettings;
  logos: CompanyLogos;
  portalSettings: PortalSettings;
  documentSettings: DocumentSettings;
  nonPerformanceSettings: CompanyNonPerformanceSettings;
  encryptedDatakey: Buffer;
  taskSettings: CompanyTaskSettings;
  chatSettings?: CompanyChatSettings;
  feeSettings: CompanyFeeSettings;
  reportSettings?: CompanyReportSettings;
  ein?: string;
  loanControlSettings?: CompanyLoanControlSettings; // TODO migration followup required
}

export interface DwollaCompany {
  id: string;
  companyId: CompanyId;
  customerUrl: string;
  primaryBankAccount?: string;
  escrowBankAccount?: string;
  suspenseBankAccount?: string;
  sweepEnabled: boolean;
  plaidToken?: string;
  plaidAccountId?: string;
  plaidMicrodepositPostedEmailSent: boolean;
}

export const DEFAULT_GRACE_PERIOD = 15;
export const CompanyAdminSettings = z.object({
  sendTransferEmailImmediately: z.boolean(),
  globalGracePeriod: z.number(),
  autopayEnabled: z.boolean(),
  pastGraceEmailEnabled: z.boolean(),
  mortgageeLettersEnabled: z.boolean(),
  preActiveWelcomeEnabled: z.boolean(), // FOLLOWUP: rename to welcomeLetterEnabled
  investorRemittanceEnabled: z.boolean(),
  disableBorrowerNotifications: z.boolean().optional(), // FOLLOWUP: rename disableAllLoanNotifications
  portalBalanceForward: z.boolean().optional(),
  multiplePropertiesEnabled: z.boolean().optional(),
  generate1098Enabled: z.boolean().optional(),
  generate1098InterestThreshold: z.number().optional(),
  customerServiceBotEnabled: z.boolean().optional(),
  automateNonPerformingLoans: z.boolean(),
  enableUsioPayments: z.boolean().optional(),
  insuranceTaxTrackingEnabled: z.boolean(),
  skipEscrowTrackingWithin60Days: z.boolean(),
  lenderChatBotEnabled: z.boolean().optional(),
  newFFEnabled: z.boolean().optional(),
  applicationsEnabled: z.boolean().optional(),
  applicationsEnabledSettings: z
    .object({
      ladderLife: z.boolean(),
    })
    .optional(),
  econsentEnabled: z.boolean().optional(),
  printAndMailEnabled: z.boolean().optional(),
  loanWorkflowsEnabled: z.boolean().optional(),
  billingSettings: CompanyBillingSettings,
  investorView: z.boolean().optional(),
  mfaAppUsersEnabled: z.boolean().optional(),
  creditPendingPaymentsOnPayoff: z.boolean().optional(),
  exactPaymentsOnly: z.boolean().optional(),
  phoneDialerFlagEnabled: z.boolean().optional(), // Note: this is a WHEDA feature that can be unflagged after we have dialer reporting in
  whedaEnabled: z.boolean().optional(),
});

export type CompanyAdminSettings = z.infer<typeof CompanyAdminSettings>;
export type CompanyAdminSetting = keyof NonNullable<CompanyAdminSettings>;

export const DEFAULT_COMPANY_ADMIN_SETTINGS: Required<CompanyAdminSettings> = {
  sendTransferEmailImmediately: false,
  globalGracePeriod: DEFAULT_GRACE_PERIOD,
  autopayEnabled: true,
  pastGraceEmailEnabled: false,
  mortgageeLettersEnabled: false,
  preActiveWelcomeEnabled: false,
  investorRemittanceEnabled: false,
  disableBorrowerNotifications: false,
  portalBalanceForward: false,
  multiplePropertiesEnabled: false,
  generate1098Enabled: true,
  generate1098InterestThreshold: 600,
  customerServiceBotEnabled: false,
  automateNonPerformingLoans: false,
  enableUsioPayments: false,
  insuranceTaxTrackingEnabled: false,
  skipEscrowTrackingWithin60Days: true,
  lenderChatBotEnabled: false,
  newFFEnabled: true,
  applicationsEnabled: false,
  applicationsEnabledSettings: {
    ladderLife: false,
  },
  econsentEnabled: false,
  printAndMailEnabled: false,
  loanWorkflowsEnabled: false,
  billingSettings: DEFAULT_BILLING_SETTINGS,
  investorView: false,
  mfaAppUsersEnabled: false,
  creditPendingPaymentsOnPayoff: false,
  exactPaymentsOnly: false,
  phoneDialerFlagEnabled: false,
  whedaEnabled: false,
};

export const DEFAULT_COMPANY_LOGOS: CompanyLogos = {
  portalLogo: false,
  emailLogo: false,
};

export const CompanyTaskSettings = z.object({
  pastGraceEnabled: z.boolean(),
  loanFlaggedTooLongEnabled: z.boolean(),
  forwardMiscollectedFundsEnabled: z.boolean(),
  mailPreActiveWelcomeLetterEnabled: z.boolean(),
  mailMonthlyStatementEnabled: z.boolean(),
  mailGoodbyeLetterEnabled: z.boolean(),
  mailMortgageeLetterEnabled: z.boolean(),
  disburseEscrowFundsEnabled: z.boolean(),
  generatePayoffStatementEnabled: z.boolean(),
});

export type CompanyTaskSettings = z.infer<typeof CompanyTaskSettings>;

export const DEFAULT_COMPANY_TASK_SETTINGS: CompanyTaskSettings = {
  pastGraceEnabled: true,
  loanFlaggedTooLongEnabled: true,
  forwardMiscollectedFundsEnabled: true,
  mailPreActiveWelcomeLetterEnabled: true,
  mailMonthlyStatementEnabled: true,
  mailGoodbyeLetterEnabled: true,
  mailMortgageeLetterEnabled: true,
  disburseEscrowFundsEnabled: true,
  generatePayoffStatementEnabled: true,
};

export const CompanyChatSettings = z.object({
  chatGptContext: z.string().optional(),
});
export type CompanyChatSettings = z.infer<typeof CompanyChatSettings>;
