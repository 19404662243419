import {
  AutopaySettings,
  CompanyFragment,
  LoanBillSettingsType,
  PortalLoanSnapshot,
  PortalPaymentDueFragment,
  PortalPaymentFragment,
} from '@willow/graphql-iso/src/portal';
import { convertNumberToOrdinal, phoneNumberFormat } from '@willow/shared-iso';
import { getNextPaymentDueDate, NamedMemo, paymentAmountFormat, paymentDateFormat } from '@willow/shared-web';
import { PortalPaymentDueSubStatus } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../../App';
import { Alert, Props as AlertProps } from '../../alert/Alert';

export interface Props {
  isAdditionalPayment: boolean;
  company: CompanyFragment;
  totalAmountDue: number;
  paymentsDue: PortalPaymentDueFragment[];
  processingPayments: PortalPaymentFragment[];
  subStatus?: PortalPaymentDueSubStatus;
  hasOutstandingBalance?: boolean;
  autopaySettings?: PortalLoanSnapshot['autopaySettings'];
  hasInterestReserve: boolean;
  hasPayoffStatement: boolean;
  autopayEnabledByServicer: boolean;
  delinquentInterestRate: number;
  currentBillSettingsType: LoanBillSettingsType;
  activeWorkoutPlan: PortalSelectedLoan['currentSnapshot']['activeWorkoutPlan'] | undefined;
}

export const PaymentFormAlerts = NamedMemo<Props>(
  'PaymentFormAlerts',
  ({
    isAdditionalPayment,
    company,
    totalAmountDue,
    paymentsDue,
    processingPayments,
    subStatus,
    hasOutstandingBalance,
    autopaySettings,
    hasInterestReserve,
    hasPayoffStatement,
    autopayEnabledByServicer,
    delinquentInterestRate,
    currentBillSettingsType,
    activeWorkoutPlan,
  }) => {
    const { isPastDue, paymentFail } = subStatus || {};
    const paymentDate = getNextPaymentDueDate(paymentsDue);

    // When builder is in charge of the loan, only show that alert message
    if (currentBillSettingsType === LoanBillSettingsType.Builder) {
      return (
        <AlertWrapper
          title="Do not make a payment unless instructed"
          subtitle="Builder is currently responsible for payments"
          level="ERROR"
        />
      );
    }

    return (
      <>
        {hasInterestReserve && totalAmountDue > 0 && (
          <AlertWrapper
            title="Remaining balance due"
            subtitle={`Interest reserve does not cover all of the amount due. Please pay the remaining balance of ${paymentAmountFormat(
              totalAmountDue,
            )}`}
            level="ERROR"
          />
        )}
        {isPastDue && !paymentFail && (
          <AlertWrapper title="Account is past due" subtitle="Please pay immediately to avoid fees" level="ERROR" />
        )}

        {paymentFail && (
          <AlertWrapper
            title="Payment failed to process"
            subtitle={
              <>
                Please try again
                {company?.supportPhone && (
                  <>
                    {' '}
                    or call <a href={`tel:${company.supportPhone}`}>{phoneNumberFormat(company.supportPhone)}</a> for
                    help
                  </>
                )}
              </>
            }
            level="ERROR"
          />
        )}
        {hasOutstandingBalance && !isPastDue && !paymentFail && (
          <AlertWrapper
            title="Outstanding balance due"
            subtitle={
              autopaySettings
                ? 'Unpaid balance will be added for auto-payment'
                : `Balance must be paid in full by ${paymentDateFormat(paymentDate, true)}`
            }
            level={isPastDue ? 'ERROR' : 'INFO'}
          />
        )}

        {delinquentInterestRate > 0 && (
          <AlertWrapper
            title="Default interest is charged"
            subtitle="Loan is in default and additional interest is billing"
            level="ERROR"
          />
        )}

        {activeWorkoutPlan && activeWorkoutPlan.type === 'REPAYMENT_PLAN' && (
          <AlertWrapper
            title="Active repayment plan in place"
            subtitle="Loan is current when repayments are completed"
            level="ERROR"
          />
        )}

        {hasPayoffStatement && processingPayments.length > 0 && (
          <AlertWrapper
            title="Payoff statement out of date"
            subtitle="Request a new payoff statement to ensure accuracy"
            level="WARNING"
          />
        )}

        {autopayEnabledByServicer &&
          autopaySettings &&
          !isAdditionalPayment &&
          (totalAmountDue > 0 || processingPayments.length > 0) && (
            <AlertWrapper
              title="No Action Required: Withdraw Scheduled"
              subtitle={getMessageFromAutopaySettings(autopaySettings)}
              level="INFO"
            />
          )}
      </>
    );
  },
);

const AlertWrapper = NamedMemo<AlertProps>('Alert', ({ title, subtitle, level }) => {
  return (
    <div className="payment-form__alert" data-testid="payment-form-alert">
      <Alert title={title} subtitle={subtitle} level={level} />
    </div>
  );
});

const getMessageFromAutopaySettings = (autopaySettings: AutopaySettings) => {
  if (autopaySettings.__typename === 'UsioAutopaySettings') {
    const { dayOfTheMonth } = autopaySettings;
    if (dayOfTheMonth) {
      const ordinal = convertNumberToOrdinal(dayOfTheMonth);
      return `Payment will be processed in full automatically on the ${ordinal} of the month`;
    }
  }
  return 'Payment will be processed in full automatically';
};
